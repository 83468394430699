import { type ComponentProps } from 'react';

import { classMerge } from '../utils/cn';

export const MarkdownIcon = ({ className }: ComponentProps<'svg'>) => {
  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={classMerge('h-5 w-5', className)}
    >
      <path
        style={{ fill: '#ffffff', stroke: 'none' }}
        d="m 65,3 0,19 19,0 z"
      />
      <path
        style={{ fill: '#EBEBDA', stroke: '#777777', strokeWidth: 2 }}
        d="m 65,3 0,19 19,0 0,74 -72,0 0,-93 53,0 19,19"
      />
      <path
        style={{ fill: '#EBEBDA', stroke: '#777777', strokeWidth: 8 }}
        d="M 25,70 25,30 35,55 45,30 45,70 M 55,70 55,33 Q 70,33 70,48 Q 70,68 55,66 z"
      />
    </svg>
  );
};
