import { ComponentProps } from 'react';

import { classMerge } from '../utils/cn';

export const EpubIcon = ({ className }: ComponentProps<'svg'>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 303.188 303.188"
      xmlSpace="preserve"
      className={classMerge('h-5 w-5', className)}
    >
      <g>
        <polygon
          style={{ fill: '#E4E4E4' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525"
        />
        <polygon
          style={{ fill: '#007934' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <path
          style={{ fill: '#007934' }}
          d="M201.981,130.748c5.465-5.466,5.467-14.329,0-19.795l-48.938,48.937l-39.039-39.039l39.039-39.039
		l14.57,14.57l-22.366,22.368l9.897,9.897l27.315-27.316c2.735-2.733,2.735-7.165,0-9.898l-24.468-24.469
		c-2.733-2.733-7.163-2.733-9.897,0l-48.938,48.937c-2.732,2.733-2.732,7.165,0,9.898l48.938,48.937
		c2.734,2.733,7.164,2.733,9.897,0L201.981,130.748z"
        />
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M83.145,274.527c-6.191,0-11-1.646-14.424-4.939c-3.424-3.292-5.136-7.98-5.136-14.062
			c0-6.279,1.586-11.097,4.759-14.456c3.172-3.358,7.701-5.038,13.586-5.038c5.602,0,9.928,1.46,12.979,4.381
			c3.052,2.921,4.578,7.127,4.578,12.618v5.71H76.549c0.087,2.079,0.848,3.708,2.28,4.89c1.434,1.182,3.386,1.772,5.858,1.772
			c2.253,0,4.327-0.213,6.22-0.64c1.892-0.427,3.942-1.154,6.152-2.182v9.222c-2.013,1.028-4.092,1.739-6.234,2.133
			C88.681,274.331,86.12,274.527,83.145,274.527z M82.391,244.762c-1.51,0-2.773,0.476-3.79,1.428
			c-1.018,0.952-1.614,2.445-1.789,4.479h11.027c-0.045-1.794-0.554-3.227-1.527-4.299S84.031,244.762,82.391,244.762z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M142.315,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.644
			v15.917h-12.963v-47.979H124.1c6.06,0,10.61,1.324,13.651,3.971C140.794,232.51,142.315,236.273,142.315,241.152z
			 M120.458,247.387h2.363c1.947,0,3.495-0.546,4.645-1.641c1.148-1.094,1.723-2.604,1.723-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.348C120.458,236.36,120.458,247.387,120.458,247.387z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M190.754,225.892v28.88c0,6.279-1.778,11.141-5.333,14.587c-3.556,3.446-8.681,5.169-15.375,5.169
			c-6.542,0-11.568-1.674-15.08-5.021c-3.511-3.347-5.267-8.16-5.267-14.44v-29.175h13.028v28.158c0,3.392,0.635,5.852,1.903,7.384
			c1.269,1.532,3.14,2.297,5.612,2.297c2.646,0,4.566-0.76,5.759-2.281c1.192-1.521,1.789-4.009,1.789-7.466v-28.092
			L190.754,225.892L190.754,225.892z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M200.829,225.892h16.736c6.52,0,11.36,0.968,14.522,2.905c3.161,1.936,4.742,4.95,4.742,9.041
			c0,2.691-0.689,4.988-2.068,6.892c-1.378,1.903-3.194,3.129-5.447,3.676v0.328c2.975,0.788,5.13,2.1,6.465,3.938
			c1.334,1.838,2.002,4.223,2.002,7.154c0,4.354-1.636,7.783-4.906,10.288c-3.271,2.506-7.729,3.758-13.373,3.758h-18.673V225.892z
			 M213.792,244.302h3.905c1.859,0,3.298-0.382,4.315-1.148c1.018-0.766,1.525-1.903,1.525-3.413c0-2.691-2.034-4.037-6.104-4.037
			h-3.643v8.598H213.792z M213.792,253.787v10.075h4.562c4.047,0,6.071-1.707,6.071-5.12c0-1.597-0.542-2.822-1.625-3.676
			c-1.083-0.853-2.652-1.28-4.709-1.28L213.792,253.787L213.792,253.787z"
          />
        </g>
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  );
};
