import { ComponentProps } from 'react';

import { classMerge } from '../utils/cn';

export const UnknownFileIcon = ({ className }: ComponentProps<'svg'>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classMerge('h-5 w-5', className)}
      viewBox="0 0 303.188 303.188"
      xmlSpace="preserve"
    >
      <g>
        <polygon
          style={{ fill: '#E8E8E8' }}
          points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
        />
        <g>
          <g>
            <path
              style={{ fill: '#333E48' }}
              d="M126.352,116.582h-8.881V92.275l0.087-3.994l0.145-4.368c-1.476,1.476-2.499,2.442-3.074,2.901
				l-4.828,3.88l-4.279-5.346l13.532-10.773h7.299L126.352,116.582L126.352,116.582z"
            />
            <path
              style={{ fill: '#333E48' }}
              d="M167.122,95.578c0,7.336-1.203,12.768-3.607,16.293c-2.404,3.524-6.105,5.285-11.104,5.285
				c-4.847,0-8.5-1.82-10.962-5.459c-2.461-3.639-3.692-9.013-3.692-16.119c0-7.413,1.197-12.876,3.592-16.392
				c2.394-3.514,6.082-5.271,11.063-5.271c4.846,0,8.509,1.84,10.988,5.517C165.88,83.109,167.122,88.49,167.122,95.578z
				 M146.577,95.578c0,5.153,0.446,8.845,1.336,11.076c0.891,2.233,2.39,3.348,4.498,3.348c2.068,0,3.563-1.131,4.481-3.391
				c0.92-2.26,1.379-5.937,1.379-11.033c0-5.15-0.465-8.852-1.393-11.104c-0.93-2.249-2.419-3.376-4.468-3.376
				c-2.088,0-3.582,1.127-4.483,3.376C147.028,86.727,146.577,90.428,146.577,95.578z"
            />
            <path
              style={{ fill: '#333E48' }}
              d="M193.526,116.582h-8.879V92.275l0.087-3.994l0.144-4.368c-1.475,1.476-2.5,2.442-3.074,2.901
				l-4.826,3.88l-4.282-5.346l13.532-10.773h7.299L193.526,116.582L193.526,116.582z"
            />
          </g>
          <g>
            <path
              style={{ fill: '#333E48' }}
              d="M133.533,153.166c0,7.336-1.201,12.767-3.605,16.29c-2.405,3.525-6.106,5.287-11.105,5.287
				c-4.847,0-8.501-1.819-10.963-5.458c-2.461-3.639-3.691-9.012-3.691-16.119c0-7.414,1.197-12.878,3.592-16.393
				c2.394-3.515,6.082-5.271,11.063-5.271c4.846,0,8.509,1.837,10.988,5.516C132.293,140.696,133.533,146.079,133.533,153.166z
				 M112.989,153.166c0,5.152,0.446,8.845,1.337,11.076c0.891,2.232,2.39,3.346,4.497,3.346c2.068,0,3.562-1.129,4.481-3.389
				c0.919-2.261,1.378-5.938,1.378-11.033c0-5.152-0.463-8.855-1.393-11.105c-0.929-2.25-2.418-3.375-4.467-3.375
				c-2.088,0-3.583,1.125-4.483,3.375C113.438,144.311,112.989,148.014,112.989,153.166z"
            />
            <path
              style={{ fill: '#333E48' }}
              d="M159.939,174.167h-8.879v-24.306l0.087-3.994l0.144-4.366c-1.477,1.474-2.5,2.442-3.073,2.903
				l-4.829,3.877l-4.279-5.344l13.532-10.775h7.298v42.005H159.939z"
            />
            <path
              style={{ fill: '#333E48' }}
              d="M193.526,174.167h-8.879v-24.306l0.087-3.994l0.144-4.366c-1.475,1.474-2.5,2.442-3.074,2.903
				l-4.826,3.877l-4.282-5.344l13.532-10.775h7.299L193.526,174.167L193.526,174.167z"
            />
          </g>
        </g>

        <polygon
          style={{ fill: '#333E48' }}
          points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
        />
        <polygon
          style={{ fill: '#D1D3D3' }}
          points="219.821,50.525 270.346,50.525 219.821,0 	"
        />
      </g>
    </svg>
  );
};
